<template>
  <div class="mainform">
    <div class="mainHeader">
      创建8D报告单基于质量通知
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form fbxj-table">
      <div class="xunjia-news">
        <span></span>基本信息
      </div>
      <el-form class="bascform" ref="BasicForm" :model="BasicForm" label-width="130px">
        <div class="content">
          <div class="col col4">
            <el-form-item label="质量通知单编号">
              <el-input disabled v-model="BasicForm.bh" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="单据标题">
              <el-input disabled v-model="BasicForm.bt" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商">
              <el-input disabled v-model="BasicForm.gys" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称">
              <el-input disabled placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料编码">
              <el-input disabled v-model="BasicForm.bm" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="工厂">
              <el-input disabled v-model="BasicForm.gc" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料描述">
              <el-input disabled v-model="BasicForm.ms" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="FCA要求时间">
              <el-input v-model="BasicForm.fca" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="ICA要求时间">
              <el-input v-model="BasicForm.ica" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="BasicForm.bz" placeholder="请填写备注"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="xunjia-news">
          <span></span>问题描述
        </div>
        <div class="fkpz content">
          <div class="col col4">
            <el-form-item label="问题类型">
              <el-select v-model="ProblemForm.wtLx" filterable placeholder="请选择">
                <el-option label="实物问题" value="1"></el-option>
                <el-option label="交付问题" value="2"></el-option>
                <el-option label="产能问题" value="3"></el-option>
                <el-option label="包装问题" value="4"></el-option>
                <el-option label="其他" value="5"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="重视度">
              <el-select v-model="ProblemForm.zsd" filterable placeholder="请选择">
                <el-option label="极高" value="1"></el-option>
                <el-option label="高" value="2"></el-option>
                <el-option label="一般" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="紧急度">
              <el-select v-model="ProblemForm.jzd" filterable placeholder="请选择">
                <el-option label="非常紧急" value="1"></el-option>
                <el-option label="紧急" value="2"></el-option>
                <el-option label="一般" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="紧急度">
              <el-button type="primary" size="mini" plain>上传附件</el-button>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="问题详述">
              <el-input type="textarea" v-model="BasicForm.wtXs" placeholder="请填写内容"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="action">
        <el-button class="submit" type="primary" size="medium" @click="preservation">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { baseURL } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'AddExceptionReport',
  props: [],
  data: function () {
    return {
      BasicForm: {},
      ProblemForm: {}
    }
  },
  mounted () {

  },
  created () {

  },
  methods: {
    // 数据测试
    creatData () {
    },
    // 提交
    preservation () {
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .fbxj-table{
    .xunjia-news{
      color: #666;
      font-size: 16px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
      span{
        display: inline-block;
        width: 3px;
        background: #ccc;
        vertical-align: -4px;
        height: 20px;
        background: #4487fa;
        margin-right: 5px;
      }
    }
    .action{
      margin: 10px 0 30px;;
    }
    .fkpz{
      padding: 20px 0 0;
    }
  }
</style>
